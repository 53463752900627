export const subjectKanpoList = [
  { id: 1, value: '彙報' },
  { id: 2, value: '伺指令' },
  { id: 3, value: '外報' },
  { id: 4, value: '閣令' },
  { id: 5, value: '観象' },
  { id: 6, value: '官庁事項' },
  { id: 7, value: '規則' },
  { id: 8, value: '宮廷錄事' },
  { id: 9, value: '局令' },
  { id: 10, value: '訓示' },
  { id: 11, value: '訓令' },
  { id: 12, value: '軍令' },
  { id: 13, value: '警察令' },
  { id: 14, value: '決算' },
  { id: 15, value: '憲法' },
  { id: 16, value: '公告' },
  { id: 17, value: '皇室事項' },
  { id: 18, value: '皇室典範' },
  { id: 19, value: '皇室令' },
  { id: 20, value: '告示' },
  { id: 21, value: '告諭' },
  { id: 22, value: '國会' },
  { id: 23, value: '国会事項' },
  { id: 24, value: '最高裁判所規則' },
  { id: 25, value: '在滿教務部令' },
  { id: 26, value: '雜事' },
  { id: 27, value: '雑報' },
  { id: 28, value: '指令' },
  { id: 29, value: '辞令' },
  { id: 30, value: '授爵' },
  { id: 31, value: '詔書' },
  { id: 32, value: '詔勅' },
  { id: 33, value: '條約' },
  { id: 34, value: '省令' },
  { id: 35, value: '條例' },
  { id: 36, value: '敍任' },
  { id: 37, value: '制令' },
  { id: 38, value: '政令' },
  { id: 39, value: '大使館令' },
  { id: 40, value: '達' },
  { id: 41, value: '庁令' },
  { id: 42, value: '勅諭' },
  { id: 43, value: '勅令' },
  { id: 44, value: '通商報告' },
  { id: 45, value: '通達' },
  { id: 46, value: '帝國議会' },
  { id: 47, value: '布告' },
  { id: 48, value: '布達' },
  { id: 49, value: '府令' },
  { id: 50, value: '法律' },
  { id: 51, value: '本部令' },
  { id: 52, value: '諭告' },
  { id: 53, value: '諭達' },
  { id: 54, value: '予算' },
  { id: 55, value: '律令' },
]
