
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import TheSearchResultMain from '@/components/organisms/TheSearchResultMain.vue'
import TheSearchImageResultMain from '@/components/organisms/TheSearchImageResultMain.vue'
import TheSearchResultLeftColumn from '@/components/organisms/TheSearchResultLeftColumn.vue'
import ModalSheetMobile from '@/components/organisms/ModalSheetMobile.vue'
import LoadingView from '@/components/molecules/LoadingView.vue'

export default defineComponent({
  name: 'SearchResultPage',
  components: {
    TheSearchResultMain,
    TheSearchImageResultMain,
    TheSearchResultLeftColumn,
    ModalSheetMobile,
    LoadingView,
  },
  props: {
    pageNo: {
      type: String,
      default: '1',
    },
    component: {
      type: String,
      required: true,
    },
    collectionId: {
      type: String,
      required: false,
    },
  },
  setup () {
    const store = useStore()

    const result = computed(() => store.getters.ItemList)
    const isProcessing = computed(() => store.getters.itemIsProcessing)

    return {
      result,
      isProcessing,
      showModal: ref(false),
    }
  },
})
